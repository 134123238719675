import React from "react";

import Layout from "../../components/layout";
import {GettingStartedSidebar as Sidebar} from "../../components/sidebar";
import {GettingStartedBreadcrumb as Breadcrumb} from "../../components/breadcrumb";
import {AskHowToInfoAlert} from "../../components/alert";
import {ApiLink} from "../../components/link";
import {CodeSnippet} from "../../components/snippet";

const pageTitle = "Bulk Fetching";

const Page = () => (
    <Layout pageTitle={pageTitle}
            sidebarNav={<Sidebar/>}
            mobileNav={<Breadcrumb activeName={pageTitle}/>}
    >
        <p>
            The goal of bulk fetching is to minimize the response time for a query.
        </p>
        <p>
            One of the ways to reduce response time is to minimize
            expensive network traffic between the server and client. It can be
            useful when working with recordsets with a large number of rows.
        </p>
        <p>
            SQLAPI++ library provides support for {' '}<em>bulk row fetching</em>,
            which means that multiple records can be retrieved at once during a
            single fetch, rather than retrieving one record at a time from the data
            source. To set bulk row fetching you should set {' '}<code>PreFetchRows</code>{' '}
            using {' '}<ApiLink>SACommand::setOption</ApiLink>{' '}
            before the command execution. It is safe to use {' '}<code>PreFetchRows</code>{' '}
            option for any DBMS. If DBMS does not support bulk row fetching then
            this option will be ignored.
        </p>

        <CodeSnippet>{`
SACommand cmd(&con);
cmd.setCommandText(_TSA("Select * from TEST_BULK"));

// this instructs the library to allocate a buffer for 100 rows
// and, using respective native API, request data from server in pages of 100 rows
cmd.setOption(SACMD_PREFETCH_ROWS) = "100";

cmd.Execute();
while(cmd.FetchNext())
{
    // process result set row by row as usual
}
        `.trim()}
        </CodeSnippet>

        <p>
            The value of {' '}<code>PreFetchRows</code>{' '} option specifies
            how many rows you wish to retrieve during a single fetch when using
            bulk row fetching. The default value is {' '}<code>"1"</code>; it means that you are not
            using bulk row fetching.
        </p>
        <p>
            See complete {' '}<a href="/Examples/bulk_fetch.cpp">example</a>{' '} to
            compare time expended on fetching rows with and without bulk mode.
        </p>

        <AskHowToInfoAlert/>
    </Layout>
);

export default Page;
